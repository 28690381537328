'use client';
import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { usePrevious } from '@/hooks/usePrevious';
import { isSameParams } from '@/app/(authenticated)/premises/(premiseId)/reportProblem/isSameParams';
import { isSamePathname } from '@/app/(authenticated)/premises/(premiseId)/reportProblem/isSamePathname';

export function usePathChanged(trigger: () => void) {
    const pathname = usePathname();
    const previousPathname = usePrevious(pathname);

    const searchParams = useSearchParams();
    const previousSearchParams = usePrevious(searchParams);

    // Do something when we navigate
    useEffect(() => {
        const samePath = isSamePathname(pathname, previousPathname);
        const sameParams = isSameParams(searchParams, previousSearchParams);

        const needsTrigger = !samePath || !sameParams;
        if (!needsTrigger) {
            return;
        }

        trigger();
    }, [pathname, searchParams, previousPathname, previousSearchParams, trigger]);
}
