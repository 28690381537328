import { PremiseDataAddress } from '@/common/interfaces/containers/premises/PremiseData';
import { safeJoin } from '../../../../util/strings/safeJoin';

export function getAddressLine1(address: PremiseDataAddress): string {
    const parts = [
        address.houseNumber,
        address.preDirection,
        address.streetName,
        address.streetSufix,
        address.postDirection,
    ];

    return safeJoin(parts);
}
