'use client';
import { normalizeValue } from '@/common/util/strings/normalizeValue';

export function normalizePath(value: string): string {
    let normalized = normalizeValue(value);

    // Remove trailing slash
    if (normalized?.endsWith('/')) {
        normalized = normalized.slice(0, -1);
    }

    return normalized;
}
