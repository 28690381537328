import { config } from '@/common/util/environment';
import { normalizeSearchQuery } from '@/common/util/strings/normalizeSearchQuery';

export function getSearchURL(query: string): URL {
    // Normalize the query value so we don't trigger updates when typing spaces
    const searchQuery = normalizeSearchQuery(query);
    if (!searchQuery) {
        return undefined;
    }

    const url = new URL(`${config.apiBase}/authenticated/data/search`);
    url.searchParams.set('query', searchQuery);

    return url;
}
