import { isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { replaceTokens } from '../../strings/replaceTokens';

// Months in AP Style
const monthSubstitutions: Record<string, string> = {
    January: 'Jan.',
    February: 'Feb.',
    August: 'Aug.',
    September: 'Sept.',
    October: 'Oct.',
    November: 'Nov.',
    December: 'Dec.',
};

function includesShortMonth(dateFormat: string): boolean {
    return dateFormat.includes('MMM') && !dateFormat.includes('MMMM');
}

function abberviateMonths(dateString: string): string {
    return replaceTokens(dateString, monthSubstitutions);
}

export function formatEST(date: Date, dateFormat: string): string {
    if (!isValid(date)) {
        return undefined;
    }

    // Determine if we need custom logic to replace short months
    const shortMonth = includesShortMonth(dateFormat);

    // If using short months, start by formatted the date with the full month name (then do a replacment with the abbreviation afterwards)
    if (shortMonth) {
        dateFormat = dateFormat.replaceAll('MMM', 'MMMM');
    }

    let formatted: string;
    try {
        formatted = formatInTimeZone(date, 'America/New_York', dateFormat);

        // Go back and replace the full month name with the abbreviated version
        if (shortMonth) {
            formatted = abberviateMonths(formatted);
        }
    } catch (error) {
        // Unable to format date
    }

    return formatted;
}
