import { useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { kebabCase } from 'lodash';
import { normalizeValue } from '@/common/util/strings/normalizeValue';

/**
 * Make sure we don't repeatedly generate new ids if we don't have a control id
 * @param id
 * @returns
 */
export function useStableUniqueId(id?: string): string {
    const backupRef = useRef(uuidv4());

    const stableId = useMemo(() => {
        let normalizedId = normalizeValue(id);
        if (!normalizedId) {
            return backupRef.current;
        }

        normalizedId = kebabCase(normalizedId);
        return normalizedId;
    }, [id]);

    return stableId;
}
